<template>
    <div>
       <b-card no-body class="border mt-1">
        <b-card-header class="p-1">
            <div>
            <b-card-title>{{$t('curlang')=='en'?'Delete Item':'Eliminar Articulo'}}</b-card-title>
            </div>
            <div>
            <b-form-group>
        <b-input-group>
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            :placeholder="$t('curlang')=='en'?'Filter by Search':'Filtrar por Busqueda'"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              {{this.$t('curlang')=='en'?'Clear':'Limpiar'}}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
        </div>
        </b-card-header> 
        <b-table
         :busy="isBusy"
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        selectable
        select-mode="range"
         @row-clicked="DeleteItem"
        >
         <template #table-busy>
        <div class="text-center text-success my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #cell(description)="data">
        <div v-html="data.value"></div>
      </template>
           <template v-slot:cell(status)="data">
        <b-badge
        :variant="data.value?'light-success':'light-danger'"
        >
       {{data.value?'Active':'Desactive'}}
        </b-badge>
      </template>
        </b-table>
         <b-col cols="12">
       <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="md"
              class="my-0 mt-1 mb-1"
            />
    </b-col>
       </b-card>
    </div>
</template>
<script>
import {BCard,BCardHeader,BCardTitle,BCardBody,BCardText,BTable,BSpinner,BAvatar,BModal,BImg,BAlert,
BRow,BCol,BFormInput,BFormGroup,BInputGroup,BInputGroupAppend,BButton,BPagination,BBadge} from "bootstrap-vue"
import SellerService from '@/services/SellerService'
export default {
    components:{BCard,BCardHeader,BCardTitle,BCardBody,BTable,BSpinner,BAvatar,BModal,BCardText,BImg,BAlert,
    BRow,BCol,BFormInput,BFormGroup,BInputGroup,BInputGroupAppend,BButton,BPagination,BBadge},
    data(){
        return{
            isBusy:true,
            openmodal:false,
              fields:[{label:this.$t("curlang")=="en"?"Item Title":"Titulo del Articulo",key:"title"},
            {label:this.$t("curlang")=="en"?"Item Description":"Descripcion del Articulo",key:"description"},
            {label:this.$t("curlang")=="en"?"Item Status":"Estado del Articulo",key:"status"}
            ],
            items:[],
          perPage: 10,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
        }
    },
    mounted(){
        this.getItemsStock()
         this.totalRows = this.items.length
    },
    methods:{
         onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    
        getItemsStock:async function(){
      const it=(await SellerService.getItemsStock(JSON.parse(localStorage.getItem("auth")).discordId)).data
      this.items=it
      this.totalRows = this.items.length
      this.isBusy=false
    },
        DeleteItem:function(item){
            this.$swal({
        title: 'Are you sure to Delete this Item?',
        text: "",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete!',
        cancelButtonText:'No, Cancel',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
           const ins=(await SellerService.DeleteItem(JSON.parse(localStorage.getItem("auth")).discordId,item._id))
            this.getItemsStock()
          this.$swal({
            icon: 'success',
            title: '',
            text: 'This Item Has Deleted',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
        },
 
    }
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>